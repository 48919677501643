import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { routerExtraReducer, RouterExtraState } from '../router/store/router.reducer';
import { operatorsReducer, OperatorsState } from '../operators/store/operators.reducer';
import { roleReducer, RoleState } from '../role/store/role.reducer';
import { dashboardReducer, DashboardState } from '../dashboard/store/dashboard.reducer';
import { ActionReducerMap } from '@ngrx/store';
import { AuthEffects } from '../auth/store/auth.effects';
import { RouterEffects } from '../router/store/router.effects';
import { OperatorsEffect } from '../operators/store/operators.effect';
import { RoleEffect } from '../role/store/role.effect';
import { DashboardEffect } from '../dashboard/store/dashboard.effect';
import { CoreEffects } from './core.effects';
import { coreReducer, CoreState } from "./core.reducer";

export interface AppGlobalState {
  core: CoreState
  router: RouterReducerState
  routerExtra: RouterExtraState
  operators: OperatorsState
  role: RoleState
  dashboard: DashboardState
}

export const StoreReducers: ActionReducerMap<AppGlobalState> = {
  core: coreReducer,
  router: routerReducer,
  routerExtra: routerExtraReducer,
  operators: operatorsReducer,
  role: roleReducer,
  dashboard: dashboardReducer
};

export const StoreEffects = [
  CoreEffects,
  AuthEffects,
  RouterEffects,
  OperatorsEffect,
  RoleEffect,
  DashboardEffect
];
