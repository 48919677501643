import { provideTanStackQuery, QueryClient, withDevtools } from "@tanstack/angular-query-experimental";
import { broadcastQueryClient } from "@tanstack/query-broadcast-client-experimental";
import { signal } from "@angular/core";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  }
});

// Sync delle query su più schede
broadcastQueryClient({ queryClient, broadcastChannel: 'query-broadcast-ch' });

const tsDevToolsEnabled = signal(false);

export const toggleDevTools = (enable: boolean) => {
  tsDevToolsEnabled.set(enable);
};

export const ProvideTanstack = provideTanStackQuery(
  queryClient,
  withDevtools(() => ({
    loadDevtools: tsDevToolsEnabled()
  }))
);